import React, { useEffect, useState } from 'react'
import parse from 'parse-url'
import cn from 'classnames'

import Header from '../components/Header'

import dev from '../images/programing.svg'
import '../styles/dashboard.css'

const Dashboard = ({ location }) => {
  const { query } = parse(location.href || 'https://get')
  const [confirmPopup, setConfirmPopup] = useState(query.confirmInvite)
  useEffect(() => {
    setTimeout(() => setConfirmPopup(false), 3000)
  }, [])
  return (
    <div id="dashboard">
      <Header />
      <div className="dashboard-content">
        <span className={cn('invite-confirm', { show: confirmPopup })}>
          Invite confirmed
        </span>
        <div className="coming-soon">
          <h1>Welcome</h1>
          <img src={dev} alt="" className="development"/>
          <p><b>Sorry</b> but WEB version of REGYM isn't completed yet.</p>
          <p>Please, download IOS version from AppStore click on button below.</p>
        </div>
        <a href="itms-beta://appstore.com/app/regym/id/1504682823" className="download-button">
          Download from AppStore
        </a>
      </div>
    </div>
  )
}

export default Dashboard
